import React from "react";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// import ConnectWallet from "./ConnectWallet/ConnectWallet";
import Logo from '../assets/images/logo.svg'
import LanguageSwitcher from "./LanguageSwitcher";

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  
  
  return (
    <Navbar expand="md" className="nav-header">
      <Container>
        <Navbar.Brand href="/#welcome-page"><Image src={Logo} alt='stalkercoin logo' style={{height: '36px'}} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={currentLanguage === 'ar' ? 'me-auto' : 'ms-auto'}>
            <Nav.Link href="/">H.O.M.E.</Nav.Link>
            <Nav.Link href="/#about">{t('navigation.about')}</Nav.Link>
                      {/* <Nav.Link href="/faq">{t('navigation.faq')}</Nav.Link> */}
                      {/* <Nav.Link href="/quests">{t('navigation.quests')}</Nav.Link> */}
            <LanguageSwitcher />
            <Nav.Link className="connect-wallet-btn" href="https://dexscreener.com/solana/cqa4atnhdyqusen3yq6ehr3hatqm49wdharygkvyssg1" target="_blank">{t('banner.buyHere')}</Nav.Link>
                      {/* <ConnectWallet></ConnectWallet> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
