import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Urkflag from "../../assets/images/svg/urk-flag.svg";
import HelpUkrainePopup from "./HelpUkrainePopup";

const HelpUkraineButton = () => {
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {showHelpPopup && <HelpUkrainePopup closePopup={setShowHelpPopup} />}
      <div className="help-ukraine-btn" onClick={() => setShowHelpPopup(true)}>
        <p>
          {t("helpUkraine.btnText")}
          <span>
            <img src={Urkflag} alt="Ukr flag"></img>
          </span>
        </p>
      </div>
    </div>
  );
};

export default HelpUkraineButton;
