import React from "react";
import { useTranslation } from "react-i18next";
import ConnectWallet from "../components/ConnectWallet/ConnectWallet";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import QuestIcon from "../assets/images/quest.png";
import ReceiveMoneyIcon from "../assets/images/receive-money.png";
import ExchangeMoneyIcon from "../assets/images/exchange-money.png";
import RightArrowIcon from "../assets/images/circled-right.png";
import CountdownTimer from "../components/CountdownTimer";
import { Link } from "react-router-dom";
import DiscrodIcon from "../assets/images/socials/discord.svg";
import DexscreenerIcon from "../assets/images/socials/dexscreener.png";
import TwitterIcon from "../assets/images/socials/twitter.png";
import TelegramdIcon from "../assets/images/socials/telegram.png";
import LockImage from "../assets/images/lock-image.png";
import AboutTokenPopup from "../components/AboutTokenPopup";

const Welcome: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div id="welcome-page">
      <section className="main-banner">
        <Container className="h-100 min-vh-100">
          <Row className="h-100 min-vh-100 align-content-center align-items-center justify-content-between">
            <Col
              xs={12}
              sm={9}
              md={6}
              lg={5}
              xl={4}
              xxl={3}
              className="text-wrap py-3"
            >
              <h1>{t("banner.welcome")}</h1>
              <h4 className="mb-4">{t("banner.predesctext")}</h4>

              <Button
                className="connect-wallet-btn px-5 align-content-around"
                href="https://dexscreener.com/solana/cqa4atnhdyqusen3yq6ehr3hatqm49wdharygkvyssg1"
                target="_blank"
              >
                {t("banner.buyHere")}
              </Button>
              {/* <ConnectWallet /> */}
            </Col>
            <Col
              xs={12}
              sm={9}
              md={6}
              lg={5}
              xl={4}
              xxl={4}
              className="text-wrap py-3 mt-5"
            >
              <div>
                <div className="banner-rewards">
                  <h2>{t("rewards.bannerTitle")}</h2>
                  <h4>{t("rewards.bannerText")}</h4>
                  <div className="my-4">
                    <a className="blue-btn px-3" href="#rewards-subscriptions">
                      {t("rewards.bannerTextBtn")}
                    </a>
                  </div>
                </div>
              </div>
              {/* <h2>{t("timedown.timeuptext")}</h2>
              <div className="timer-text">
                <CountdownTimer upToDate="2024-08-04T08:00:00Z" />
              </div>
              <div className="neon-arrow-container">
                <div className="neon-arrow"></div>
            </div> */}
            </Col>
          </Row>
        </Container>
      </section>
      <section id="about-coin-section" className="about-section py-5">
        <div className="section-title">
          <h2>{t("welcome.about.title")}</h2>
          <div className="image-divider" />
        </div>
        <Container className="py-4">
          <Row className="justify-content-end">
            <Col xs={12} md={10} lg={8}>
              <div className="desctiption-text">


              <p
                  dangerouslySetInnerHTML={{
                    __html: t("welcome.about.shortDescription"),
                  }}
                ></p>
                <div className="my-3">
                  <AboutTokenPopup />
                </div>
                {/* <p
                  dangerouslySetInnerHTML={{
                    __html: t("welcome.about.descText1"),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("welcome.about.descText2"),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("welcome.about.descText3"),
                  }}
                ></p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        id="rewards-subscriptions"
        className="rewards-subscriptions-section"
      >
        <div className="section-title">
          <h2>{t("rewards.titleSection")}</h2>
          <div className="image-divider" />
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} lg={10} xl={9}>
              <h4>{t("rewards.descriptionSection1")}</h4>
              <ul className="radiation-list">
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("rewards.descroptionSection1Desc1"),
                    }}
                  />
                </li>
                <li>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("rewards.descroptionSection1Desc2"),
                    }}
                  />
                </li>
              </ul>
              <p className="text-center">
                <iframe
                  title="steam"
                  src="https://store.steampowered.com/widget/1643320/583675/"
                  frameBorder="0"
                  width={window.screen.availWidth < 646 ? window.screen.availWidth : 646}
                  height={window.screen.availWidth < 646 ? 260 : 190}
                  style={{marginLeft: "-15px"}}
                ></iframe>
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("rewards.descroptionSection1Desc4"),
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="social-section py-5">
        <div className="section-title">
          <h2>{t("welcome.social.title")}</h2>
          <div className="image-divider" />
        </div>
        <Container className="d-flex justify-content-center">
          <div className="buttons-wrap">
            {/* <a
              href="https://discord.gg/Bsszy7VG9F"
              rel="noreferrer"
              target="_blank"
            >
              <img src={DiscrodIcon} alt="discord" />
            </a> */}
            <a
              href="https://x.com/stalkercoin2"
              rel="noreferrer"
              target="_blank"
            >
              <img src={TwitterIcon} alt="twitter x" />
            </a>
            {/* <a href="https://t.me/strctoken" rel="noreferrer" target="_blank">
              <img src={TelegramdIcon} alt="telegram" />
            </a> */}
            <a
              href="https://dexscreener.com/solana/buhhrxphjqpudgnqdhcyk8v5xxwjuz3fivpauerkw8pr"
              rel="noreferrer"
              target="_blank"
            >
              <img src={DexscreenerIcon} alt="dexscreener" />
            </a>
          </div>
        </Container>
      </section>
      <section className="stalker-section py-5" id="about">
        <div className="section-title">
          <h2>{t("welcome.stalker.title")}</h2>
          <div className="image-divider" />
        </div>
        <Container className="py-4">
          <Row>
            <Col>
              <video
                data-v-7fa2e920=""
                autoPlay={false}
                loop={true}
                muted={true}
                className="w-100"
              >
                <source
                  src="https://www.stalker2.com/_nuxt/videos/assets/video/preview_320.webm"
                  type="video/webm"
                />{" "}
                <source
                  src="https://www.stalker2.com/_nuxt/videos/assets/video/preview.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Col>
            <Col
              xs={12}
              md={8}
              lg={6}
              className="d-flex flex-column justify-content-center"
            >
              <div className="desctiption-text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("welcome.stalker.descText1"),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("welcome.stalker.descText2"),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("welcome.stalker.descText3"),
                  }}
                ></p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="quests-section">
        <div className="developing-section">
          <div>
            <h4 className="text-center">
              <img src={LockImage} alt="lock" />
              {t("welcome.developingSection")}
            </h4>
          </div>
        </div>
        <div className="section-title">
          <h2>{t("welcome.questTitle")}</h2>
          <div className="image-divider" />
        </div>
        <Container className="pt-4">
          <Row className="justify-content-center">
            <Col xs={10} sm={8} md={4} lg={3} xl={2}>
              <div className="question-step">
                <Image src={QuestIcon} alt="quest" />
                <p>{t("welcome.questStep1text")}</p>
              </div>
            </Col>
            <Col
              xs={10}
              sm={8}
              md={1}
              lg={1}
              xl={1}
              className="d-flex justify-content-center align-self-center"
            >
              <Image
                src={RightArrowIcon}
                style={{ height: "40px" }}
                alt="right arrow"
              />
            </Col>
            <Col xs={10} sm={8} md={4} lg={3} xl={2}>
              <div className="question-step">
                <Image src={ReceiveMoneyIcon} alt="quest" />
                <p>{t("welcome.questStep2text")}</p>
              </div>
            </Col>
            <Col
              xs={10}
              sm={8}
              md={1}
              lg={1}
              xl={1}
              className="d-flex justify-content-center align-self-center"
            >
              <Image
                src={RightArrowIcon}
                style={{ height: "40px" }}
                className="mx-auto"
                alt="right arrow"
              />
            </Col>
            <Col xs={10} sm={8} md={4} lg={3} xl={2}>
              <div className="question-step">
                <Image src={ExchangeMoneyIcon} alt="quest" />
                <p>{t("welcome.questStep3text")}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="d-flex justify-content-center py-4">
            <Link to={"#"} className="light-btn">
              {t("welcome.actionBtn")}
            </Link>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Welcome;
