import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const AboutTokenPopup = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {/* <button className='blue-btn px-3' onClick={handleShow}>
          {t("btns.readMore")}
        </button> */}
      <div className="my-4">
        <a className="blue-btn px-3" href="#about-coin-section" onClick={handleShow}>
          {t("rewards.bannerTextBtn")}
        </a>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("welcome.about.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: t("welcome.about.descText1"),
            }}
          ></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("btns.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AboutTokenPopup;
