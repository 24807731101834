import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();
    const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  const languages = [
    {
      id: "uk",
      title: "Українська"
    },
    {
      id: "en",
      title: "English"
    },
    {
      id: "pl",
      title: "Polski"
    },
    {
      id: "cs",
      title: "Čeština"
    },
    {
      id: "de",
      title: "Deutsch"
    },
    {
      id: "fr",
      title: "Français"
    },
    {
      id: "ro",
      title: "Română"
    },
    {
      id: "bg",
      title: "Български"
    },
    {
      id: "ar",
      title: "العربية"
    },
    {
      id: "ja",
      title: "日本語"
    },
    {
      id: "ko",
      title: "한국어"
    }
  ];
  return (
    <NavDropdown title={<FontAwesomeIcon icon={faGlobe} />} id="language-nav-dropdown"
    drop="down"
          className="custom-nav-dropdown">
          {languages.map(language => <NavDropdown.Item href="#" onClick={() => changeLanguage(language.id)}>{language.title}</NavDropdown.Item>)}
              
                      </NavDropdown>
  )
}

export default LanguageSwitcher