// import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Welcome from "./pages/Welcome";
import MainLayout from "./layouts/MainLayout";
import Quests from "./pages/quests/Quests";
import Faq from "./pages/Faq";

// const NotFound = lazy(() => import('../components/NotFound/NotFound'));

const AppRoutes = (): JSX.Element => {
  return (
    // <ErrorBoundary>
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Welcome />} />
          {/* <Route path="/fqa" element={<Faq />} />
          <Route path="/quests" element={<Quests />} /> */}
        </Route>
      </Routes>
    </Router>
    // </ErrorBoundary>
  );
};

export default AppRoutes;
