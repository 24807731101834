import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HelpUkraineButton from "../components/HelpUkraine/HelpUkraineButton";

const MainLayout: React.FC = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      
      <HelpUkraineButton />
      <Header />
      <div style={{ marginTop: '-65px' }}>
        <Outlet />
      </div>
      {/* <div style={{position: "absolute", bottom: "0", width: "100%"}}> */}
        <Footer />
        {/* </div> */}
    </div>
  );
};

export default MainLayout;
