import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BackImg from "../../assets/images/back-arrow.png";
interface PHelpUkrainePopup {
  closePopup: (state: boolean) => void;
}

const HelpUkrainePopup: React.FC<PHelpUkrainePopup> = ({ closePopup }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsVisible(true);
  }, []);
  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => closePopup(false), 300); // Match the timeout to CSS transition duration
  };

  return (
    <div className={`help-ukraine-popup popup ${isVisible ? "show" : ""}`}>
      <div className="content-wrap">
        <div className="back-btn" onClick={handleClose}>
          &#8592;
        </div>
        <div className="text-wrap">
          <div
            dangerouslySetInnerHTML={{ __html: t("helpUkraine.popupText") }}
          />
          <div
            dangerouslySetInnerHTML={{ __html: t("helpUkraine.popupText2") }}
          />
        </div>
      </div>
    </div>
  );
};

export default HelpUkrainePopup;
