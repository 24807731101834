import React from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <footer className="py-3">
      <Container>
        <Navbar className="nav-header">
          <Nav className="mx-auto">
            <Nav.Link href="/#welcome-page">H.O.M.E.</Nav.Link>
            <Nav.Link href="#about-coin-section">{t("navigation.about")}</Nav.Link>
            {/* <Nav.Link href="#home">{t("navigation.faq")}</Nav.Link> */}
            {/* <Nav.Link href="#home">{t("navigation.quests")}</Nav.Link> */}
          </Nav>
        </Navbar>
      </Container>
      <Container className="py-4">
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={9}>
            <p
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: t("footer.termsText"),
              }}
            />
          </Col>
        </Row>
      </Container>
      <Container className="pb-4">
        <h5 className="text-center mt-5">&copy; {currentYear} STALKERCOIN</h5>
      </Container>
    </footer>
  );
};

export default Footer;
