import React, { useEffect} from "react";
import AppRoutes from "./AppRouter";
import { useTranslation } from 'react-i18next';


const App:React.FC = () => {
  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  // };
  const { i18n } = useTranslation();
  useEffect(() => {
    const currentLanguage = i18n.language;
    console.log('currentLanguage', currentLanguage)
    document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);
  
  return (
    <div className="App">
      <AppRoutes />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>{t("welcome")}</h1>
        <h1>{t("description")}</h1>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>

        <button onClick={() => changeLanguage("en")}>English</button>
        <button onClick={() => changeLanguage("uk")}>Українська</button>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <Container>This text in container</Container> */}
    </div>
  );
}

export default App;
